import './style.css';
import React, { Component } from 'react';
import { withSnackbar } from 'notistack';

import {
  Button,
  Divider,
  Card,
  CardHeader,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  CircularProgress,
  Snackbar
} from '@material-ui/core';

import {
  Bluetooth,
  BluetoothConnected,
  BluetoothSearching,
  ChevronRight
} from '@material-ui/icons';

import {
  withApi,
  withUser,
  withHomey,
  withMessages
} from '../../services/AthomApi';

import PeripheralView from './peripheralView';
import ServiceView from './serviceView';
import CharacteristicView from './characteristicView';
import DescriptorView from './descriptorView';

class PageToolsBLE extends Component {

  _refreshInterval = null;

  constructor(props) {
    super(props);

    this.runCommand = this.runCommand.bind(this)
    this.scrollViewRef = React.createRef();

    this.state = {
      connections: [],
      isDiscoveringDevices: false,
    }
  }

  componentDidMount() {
    // Perform an initial REFRESH to load the data on load
    setTimeout(() => {
      this.props.homey.ble.runCommand({
        command: 'REFRESH',
        opts: {},
      }).catch(this.props.handleError);
    }, 200)

    // Refresh every 10s
    // This prevents Homey from undoing the changes made in the devtool
    // When the page is closed the devtool will cleanup after 30s
    this._refreshInterval = setInterval(() => {
      this.props.homey.ble.runCommand({
        command: 'REFRESH',
        opts: {},
      }).catch(this.props.handleError);
    }, 10000)

    /*
      Listen to updates to the state of managerBLE
      If the selectedPeripheral was present first, but not present after the update a small toast is shown.
    */
    this.props.homey.ble
      .on('state', ({ ble_state }) => {
        const selectedPeripheralPresentBefore = this.state.connections.some(peripheral => {
          return peripheral.uuid === this.state.selectedPeripheralUuid && peripheral.state === "connected"
        });
        this.setState({ connections: ble_state })
        const selectedPeripheralPresentAfter = this.state.connections.some(peripheral => {
          return peripheral.uuid === this.state.selectedPeripheralUuid && peripheral.state === "connected"
        });
        if (selectedPeripheralPresentBefore && !selectedPeripheralPresentAfter) {
          this.setState({ error: 'Connection closed' })
        }
      })
      .connect()
      .catch(this.props.handleError);

  }

  componentWillUnmount() {
    clearInterval(this._refreshInterval);

    if (this.props.homey) {
      this.props.homey.ble.destroy();
    }
  }

  /*
    Send a command to Homey
  */
  async runCommand(command, opts) {
    try {
      const result = await this.props.homey.ble.runCommand({
        $timeout: 10000,
        command,
        opts,
      });
      return result;
    } catch (err) {
      console.error(err);
      this.setState({
        error: `Error running command ${command}: ${err.message}`,
      });
    }
  }

  render() {
    const advertisements = this.state.connections;
    const selectPeripheral = peripheral => {
      this.setState({ selectedPeripheralUuid: peripheral.uuid })
    }

    const selectedPeripheral = () => {
      return this.state.connections.find(peripheral => peripheral.uuid === this.state.selectedPeripheralUuid);
    }

    const selectedService = () => {
      const peripheral = selectedPeripheral();
      if (!peripheral) return;
      return peripheral.services.find(service => service.uuid === this.state.selectedServiceUuid);
    }

    const selectedCharacteristic = () => {
      const service = selectedService();
      if (!service) return;
      return service.characteristics.find(characteristic => characteristic.uuid === this.state.selectedCharacteristicUuid);
    }

    const selectedDescriptor = () => {
      const characteristic = selectedCharacteristic();
      if (!characteristic) return;
      return characteristic.descriptors.find(descriptor => descriptor.uuid === this.state.selectedDescriptorUuid);
    }

    const discover = () => {
      this.setState({ isDiscoveringDevices: true });
      this.runCommand('DISCOVER')
        .finally(() => {
          this.setState({ isDiscoveringDevices: false });
        });
    }

    return (
      <div className="ble-horizontal-container" ref={this.scrollViewRef}>
        <Snackbar open={!!this.state.error} autoHideDuration={4000} onClose={() => { this.setState({ error: null }); }}>
          <div className="toast error"> <Typography> {this.state.error} </Typography> </div>
        </Snackbar>

        <Card className="ble-card">
          <CardHeader title={<span> <Bluetooth /> Bluetooth Low Energy </span>} subheader="Advertisements" />
          <Divider />
          <CardContent>
            <Button fullWidth variant="contained" color="primary" size="medium" disabled={this.state.isDiscoveringDevices} onClick={discover}>
              Discover Devices
              {(this.state.isDiscoveringDevices) && <CircularProgress className="circularProgress" size={24} />}
            </Button>
          </CardContent>
          <Divider />

          <List subheader={<ListSubheader>Discovery results</ListSubheader>}>
            {advertisements.length ? advertisements
              .sort((a, b) => {
                // First sort by connectAppId, then by RSSI
                if (!!a.connectedAppId && !!!b.connectedAppId) return -1;
                if (!!!a.connectedAppId && !!b.connectedAppId) return 1;
                return b.rssi - a.rssi;
              })
              .map(peripheral => {
                const timeAgo = Math.round((Date.now() - peripheral.lastSeen) / 1000); // time ago in seconds
                let subtitle = `${timeAgo}s ago - RSSI: ${peripheral.rssi} ${peripheral.manufacturer}`
                const hasState = peripheral.state !== "disconnected";
                const isConnected = peripheral.state === "connected";

                if (peripheral.state === 'error') {
                  subtitle = "Error";
                } else if (hasState) {
                  subtitle = `${peripheral.state} by ${peripheral.connectedAppId}`;
                }

                return (
                  <ListItem
                    button
                    key={peripheral.uuid}
                    selected={peripheral.uuid === this.state.selectedPeripheralUuid}
                    onClick={() => { selectPeripheral(peripheral) }}>
                    <ListItemIcon>
                      {isConnected === false ? (
                        <BluetoothSearching color="disabled" />
                      ) : (
                        <BluetoothConnected color="primary" />
                      )}
                    </ListItemIcon>

                    <ListItemText
                      primary={<Typography> {peripheral.localName} <code> {peripheral.uuid} </code> </Typography>}
                      secondary={hasState ? <Typography variant="overline" color={isConnected ? "primary" : "secondary"}> {subtitle} </Typography> : subtitle} />
                    <ChevronRight />
                  </ListItem>
                );
              }) : (<ListItem> <ListItemText primary={<Typography> No advertisements </Typography>} /> </ListItem>)
            }
          </List>
        </Card>

        {
          // Display the card that show the selected peripheral
          selectedPeripheral() ? (
            <PeripheralView
              scrollRef={this.scrollViewRef.current}
              peripheral={selectedPeripheral()}
              runCommand={this.runCommand}
              selectService={(service) => {
                this.setState({ selectedServiceUuid: service.uuid })
              }}
              selectedServiceUuid={this.state.selectedServiceUuid}
            />
          ) : (null)
        }

        {
          // Display the card that show the selected service
          selectedService() ? (
            <ServiceView
              scrollRef={this.scrollViewRef.current}
              peripheral={selectedPeripheral()}
              service={selectedService()}
              runCommand={this.runCommand}
              selectCharacteristic={(characteristic) => {
                this.setState({ selectedCharacteristicUuid: characteristic.uuid })
              }}
              selectedCharacteristicUuid={this.state.selectedCharacteristicUuid}
            />
          ) : (null)
        }

        {
          // The Card that shows the selected Characteristic
          selectedCharacteristic() ? (
            <CharacteristicView
              scrollRef={this.scrollViewRef.current}
              peripheral={selectedPeripheral()}
              service={selectedService()}
              characteristic={selectedCharacteristic()}
              runCommand={this.runCommand}
              selectDescriptor={(descriptor) => {
                this.setState({ selectedDescriptorUuid: descriptor.uuid })
              }}
              selectedDescriptorUuid={this.state.selectedDescriptorUuid}
            />
          ) : (null)
        }

        {
          // The Card that shows the selected Descriptor
          selectedDescriptor() ? (
            <DescriptorView
              scrollRef={this.scrollViewRef.current}
              peripheral={selectedPeripheral()}
              service={selectedService()}
              characteristic={selectedCharacteristic()}
              descriptor={selectedDescriptor()}
              runCommand={this.runCommand}
            />
          ) : (null)
        }

      </div>
    );
  }
}

export default withSnackbar(withMessages(withApi(withUser(withHomey(PageToolsBLE, { version: '>=6.0.0' })))));
