import './style.css';
import React, {Component} from 'react';
import {
  Divider,
  Card,
  CardHeader,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  CircularProgress,
  CardContent, Button,
} from '@material-ui/core';
import {ChevronRight} from '@material-ui/icons';

class PeripheralView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isDiscoveringServices: false,
      isDiscoveringAll: false,
      isUpdatingRSSI: false,
      isConnecting: false,
      isDisconnecting: false,
    };
  }

  componentDidMount() {
    const { scrollRef } = this.props;
    scrollRef.scrollLeft = 9999;
  }

  render() {
    const { peripheral, runCommand, selectService, selectedServiceUuid } = this.props;
    const { services } = peripheral;
    

    const connect = () => { 
      this.setState({ isConnecting: true });
      runCommand('CONNECT', { peripheralUuid: peripheral.uuid })
      .finally(() => {
        this.setState({ isConnecting: false });
      });
    }

    const disconnect = () => {
      this.setState({ isDisconnecting: true });
      runCommand('DISCONNECT', { peripheralUuid: peripheral.uuid })
      .finally(() => {
        this.setState({ isDisconnecting: false });
      });
    }

    const updateRSSI = () => {
      this.setState({ isUpdatingRSSI: true });
      runCommand('UPDATE_RSSI', { peripheralUuid: peripheral.uuid })
      .finally(() => {
        this.setState({ isUpdatingRSSI: false });
      });
    }

    const discoverServices = () => {
      this.setState({ isDiscoveringServices: true });
      runCommand('DISCOVER_SERVICES', { peripheralUuid: peripheral.uuid })
      .finally(() => {
        this.setState({ isDiscoveringServices: false });
      });
    }

    const discoverAll = () => {
      this.setState({ isDiscoveringAll: true });
      runCommand('DISCOVER_ALL', { peripheralUuid: peripheral.uuid })
      .finally(() => {
        this.setState({ isDiscoveringAll: false });
      });
    }

    let manufacturerData;
    if (peripheral) {
      // serviceData.[].data is an arraybuffer, which becomes an empty object when stringified.
      // Here we iterate over the serviceData array to convert the Arraybuffers to a hexadecimal string.
      let serviceData = [];
      if (peripheral.advertisement && peripheral.advertisement.serviceData) {
        serviceData = peripheral.advertisement.serviceData;
        serviceData.forEach(sd => {
          if (sd.data) {
            sd.data = Buffer.from(sd.data).toString('hex');
          }
        });

        // manufacturerData is an arraybuffer, which becomes an empty object when stringified.
        // This converts the Arraybuffer to a hexadecimal string.
        if (peripheral.advertisement && peripheral.advertisement.manufacturerData) {
          manufacturerData = Buffer.from(peripheral.advertisement.manufacturerData).toString('hex');
        }
      }
      return (
        <Card className="ble-card">
          <CardHeader title={<span> {peripheral.localName} </span>} subheader="Peripheral"/>
          <Divider/>

        <CardContent> 
          <div className="horizontal_buttons">
            <Button
              color='primary'
              variant={'contained'}
              disabled={this.state.isConnecting || !peripheral.connectable || peripheral.state==="connecting" || peripheral.state === "connected"}
              onClick={connect}>
                {peripheral.state === "connected" ? 'Connected' : 'Connect'} 
                {(this.state.isConnecting || peripheral.state==="connecting") && <CircularProgress className="circularProgress" size={24}/> }
            </Button>
            <Button
              variant={'contained'}
              color='primary'
              disabled={this.state.isDisconnecting || peripheral.state==="disconnecting" || peripheral.state === "disconnected"}
              onClick={disconnect}>
                {peripheral.state === "disconnected" ? 'Disconnected' : 'Disconnect'} 
                {(this.state.isDisconnecting || peripheral.state==="disconnecting") && <CircularProgress className="circularProgress" size={24}/> }
            </Button>
            </div>
            <Button
            style={{marginBottom: '12px'}}
                  fullWidth
                  variant={"contained"}
                  color="primary"
                  disabled={peripheral.state !== "connected" || this.state.isDiscoveringServices}
                  onClick={discoverServices}>
                    Discover Services
                    {this.state.isDiscoveringServices && <CircularProgress className="circularProgress" size={24}/> }
                </Button>
                <Button
                fullWidth
                  variant={"contained"}
                  color="primary"
                  disabled={peripheral.state !== "connected" || this.state.isDiscoveringAll}
                  onClick={discoverAll}>
                    Discover Services & Characteristics
                    {this.state.isDiscoveringAll && <CircularProgress className="circularProgress" size={24}/> }
                  
                </Button>
                </CardContent>

          <Divider/>
          {/* Services */}
          <List subheader={<ListSubheader>Services</ListSubheader>}>
            {services.length ? services.map(service => {
              return (
                <ListItem
                  button
                  key={peripheral.uuid + service.uuid}
                  selected={service.uuid === selectedServiceUuid}
                  onClick={() => {
                    selectService(service)
                  }}
                >
                  <ListItemText
                    primary={<Typography> {service.name || 'Vendor'} <code> {service.uuid} </code> </Typography>}
                  />
                  <ChevronRight/>
                </ListItem>
              );
            }) : ( <ListItem> <ListItemText primary={<Typography> No services </Typography>}/> </ListItem>)
          }
          </List>
          <Divider/>

          {/* Device details */}
          <List subheader={<ListSubheader>Details</ListSubheader>}>
            <ListItem dense={true}>
              <ListItemText primary={<Typography> Uuid </Typography>}/>
              <ListItemSecondaryAction>
                <Typography> <code> {peripheral.uuid} </code> </Typography>
              </ListItemSecondaryAction>
            </ListItem>
              {/* Update RSSI */}
              <ListItem>
              <ListItemText primary={<span> RSSI <Button style={{marginLeft: 6}} size={"small"} color='primary' variant={"outlined"} disabled={peripheral.state !== 'connected' || this.state.isUpdatingRSSI}              
              onClick={updateRSSI}>Update</Button> </span>}/>
              <ListItemSecondaryAction>
                { this.state.isUpdatingRSSI ? <CircularProgress size={24}/> : peripheral.rssi}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary={<Typography> Gateway </Typography>}/>
              <ListItemSecondaryAction>
                <Typography> <code> {peripheral.bleHandler} </code> </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary={<Typography> Manufacturer </Typography>}/>
              <ListItemSecondaryAction>
                <Typography> <code> {peripheral.manufacturer} </code> </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary={<Typography> Address </Typography>}/>
              <ListItemSecondaryAction>
                <Typography> <code> {peripheral.address} </code> </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary={<Typography> Address Type </Typography>}/>
              <ListItemSecondaryAction>
                <Typography> <code> {peripheral.addressType} </code> </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary={<Typography> Connectable </Typography>}/>
              <ListItemSecondaryAction>
                <Typography> <code> {peripheral.connectable ? 'true' : 'false'} </code> </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary={<Typography> Tx Power level </Typography>}/>
              <ListItemSecondaryAction>
                <Typography> <code> {peripheral.advertisement.txPowerLevel} </code> </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary={<Typography> Advertised service uuids </Typography>}
                            secondary={<Typography>
                <code> {JSON.stringify(peripheral.advertisement.serviceUuids)} </code> </Typography>}/>
            </ListItem>
            <ListItem>
              <ListItemText primary={<Typography> Advertised service data (hex) </Typography>}
                            secondary={<Typography>
                              <code> {JSON.stringify(serviceData)} </code> </Typography>}/>
            </ListItem>
            <ListItem>
              <ListItemText
                primary={<Typography> Advertised Manufacturer data (hex) </Typography>}
                secondary={<Typography> <code> {JSON.stringify(manufacturerData)} </code>
                </Typography>}/>
            </ListItem>
          </List>
        </Card>
      );
    }
    return null;
  }
}

export default PeripheralView;
